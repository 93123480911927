import * as React from 'react';
import { Link } from 'gatsby';

import { Button } from '@singledesk/button';

import { Wrapper, Content, Title, Description } from './styled';

function NotFound() {
  return (
    <Wrapper>
      <Content>
        <Title>404</Title>
        <Description>Page not found</Description>
        <Link to='/'>
          <Button>Return to home page</Button>
        </Link>
      </Content>
    </Wrapper>
  );
}

export default NotFound;
